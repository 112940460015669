import axiosInstance from "./axiosInstance"; // Adjust the path accordingly
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetAdminGroup = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/admingroup", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAdminGroupByID = async (adminGroupId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/admingroup/" + adminGroupId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateAdminGroup = async (adminGroupData, adminGroupId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/admingroup/" + adminGroupId,
      adminGroupData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateAdminGroup = async (AdminGroupData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/admingroup", AdminGroupData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAdminGroups = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/admingroup/getAdminGroups?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchAdminGroup = async (searchData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/admingroup/search", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateAdminGroupByID = async (adminGroupData, adminGroupId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/admingroup/" + adminGroupId,
      adminGroupData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteAdminGroup = async (adminGroupId, token) => {
  try {
    const response = await axiosInstance.delete(SERVER_URL + "/admingroup/" + adminGroupId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export { GetAdminGroup, UpdateAdminGroupByID, UpdateAdminGroup, CreateAdminGroup, SearchAdminGroup, GetAdminGroups, GetAdminGroupByID, DeleteAdminGroup };
