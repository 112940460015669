import React from "react";
import { createRoot } from 'react-dom/client';
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "utils/store";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import App from "./app";

const root = createRoot(document.getElementById('root')); // Make sure 'root' is the correct ID in your HT

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ChakraProvider>
    </PersistGate>
  </Provider>
);

//serviceWorkerRegistration.register();
