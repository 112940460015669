import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Button,
  Stack,
  SimpleGrid,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Checkbox,
  Input,
  InputGroup,
  InputLeftElement,
  Flex,
  Skeleton,
  SkeletonText,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Paginate from "components/navigation/Paginate.js";
import Card from "components/card/Card.js";
import { GetInstitution } from "services/institutionService";
import { GetTransaction } from "services/transactionService";
import { checkprivileges } from "helpers/utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CalendarIcon } from "@chakra-ui/icons";
const { DateTime } = require("luxon");

const formatTime = (timestamp) => {
  const myDateTime = DateTime.fromISO(timestamp);
  return myDateTime.toLocaleString(DateTime.DATE_MED);
};

export default function Index() {
  const navigate = useNavigate();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(100);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [institution, setInstitution] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [transactions, setTransactions] = useState([]);

  const getTransactions = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
        dateFrom,
        dateTo,
      };

      const queryString = new URLSearchParams(pagination).toString();
      const filter = { institutions: selectedInstitutions };

      const response = await GetTransaction(queryString, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response?.data?.transactions);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const handleCheckboxChange = (option) => {
    if (selectedInstitutions.includes(option)) {
      setSelectedInstitutions(selectedInstitutions.filter((item) => item !== option));
    } else {
      setSelectedInstitutions([...selectedInstitutions, option]);
    }
  };

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setInstitutions(response?.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const clearFilters = () => {
    setSelectedInstitutions([]);
    setFrom(0);
  };

  useEffect(() => {
    !checkprivileges(privileges, ["View Reports", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    if (userState.isSuperAdmin || userState.isPartner) {
      getInstitutions();
    }
    getTransactions();
  }, []);

  return (
    <>
      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}> Transactions
        </Text>
      </Box>

      <SimpleGrid
        columns={{ sm: 1, md: 3, lg: 4 }}
        w="100%"
        p="2"
        spacing="3"
        mt="3"
      >
        {
          (userState.isSuperAdmin || userState.isPartner) &&
          <Box>
            <FormControl>
              <FormLabel fontWeight="bold">Institutions</FormLabel>
              <Menu closeOnSelect={false}>
                <MenuButton textAlign="left" as={Button} width="100%" rightIcon={<ChevronDownIcon />} borderRadius={"3em"} borderWidth="1px" borderColor="#8D91A0">
                  Select Option
                </MenuButton>
                <MenuList maxH="200px" overflowY="auto" p="2">
                  <Stack spacing={1}>
                    {institutions.map((institution) => (
                      <MenuItem key={institution._id} onClick={(e) => e.stopPropagation()}>
                        <Checkbox
                          isChecked={selectedInstitutions.includes(institution._id)}
                          onChange={() => handleCheckboxChange(institution._id)}
                        >
                          {institution.institutionName}
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </Stack>
                </MenuList>
              </Menu>
            </FormControl>
          </Box>
        }
        <Box>
          <FormLabel color="black" fontWeight={"bold"}>
            Date From
          </FormLabel>
          <InputGroup
            borderRadius={"3em"}
            borderWidth={"1px"}
            borderColor="#8D91A0"
            outlineColor={"black"}>
            <InputLeftElement pointerEvents="none">
              <CalendarIcon color="gray.300" />
            </InputLeftElement>
            <Input
              borderWidth={"0px"}
              type="date"
              placeHolder="Date From"
              color="black"
              onChange={(e) => setDateFrom(e.target.value)}
            />
          </InputGroup>
        </Box>

        <Box>
          <FormLabel color="black" fontWeight={"bold"}>
            Date To
          </FormLabel>
          <InputGroup
            borderRadius={"3em"}
            borderWidth={"1px"}
            borderColor="#8D91A0"
            outlineColor={"black"}>
            <InputLeftElement pointerEvents="none">
              <CalendarIcon color="gray.300" />
            </InputLeftElement>
            <Input
              borderWidth={"0px"}
              type="date"
              placeHolder="Date To"
              color="black"
              onChange={(e) => setDateTo(e.target.value)}
            />
          </InputGroup>
        </Box>

        <Box>
          <Flex mt={{ sm: "0", md: "0", lg: "6" }}>
            <Button
              variant="brand"
              width="100px"
              onClick={() => {
                getTransactions()
              }}>Go</Button>
            &nbsp;
            <Button
              variant="outline"
              width="100px"
              onClick={clearFilters}>Clear Filter</Button>
          </Flex>
        </Box>

      </SimpleGrid>

      {loading ? (
        <LoadingTransactions />
      ) : (
        <TableContainer mt="5">
          {userState.isSuperAdmin ? <AdminTransactions transactions={transactions} /> : <PartnerTransactions transactions={transactions} />}
        </TableContainer>
      )}
      {pageCount > 0 && (
        <Box width="100%" mt="5">
          <Paginate
            pageCount={pageCount}
            setFrom={setFrom}
            getRecords={getInstitutions}
          />
        </Box>
      )}
    </>
  );
};

const AdminTransactions = ({ transactions }) => {
  return (
    <>
      <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
        <Thead>
          <Tr>
            <Th color="#000000">Narration</Th>
            <Th color="#000000">Amount (N)</Th>
            <Th color="#000000">Platform Commision (N)</Th>
            <Th color="#000000">Partner Commission (N)</Th>
            <Th color="#000000">Institution Commission (N)</Th>
            <Th color="#000000">Transaction Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            transactions.map((transaction) => (
              <Tr>
                <Td>{transaction.narration} </Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.amount)} </Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.platformRevenue)}</Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.adminGroupRevenue)}</Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.institutionRevenue)}</Td>
                <Td>{formatTime(transaction.createdAt)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

const PartnerTransactions = ({ transactions }) => {
  return (
    <>
      <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
        <Thead>
          <Tr>
            <Th color="#000000">Narration</Th>
            <Th color="#000000">Amount (N)</Th>
            <Th color="#000000">Transaction Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.map((transaction) => (
            <Tr>
              <Td>{transaction.narration} </Td>
              <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.amount)}</Td>
              <Td>{formatTime(transaction.transactionDate)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};



const LoadingTransactions = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};