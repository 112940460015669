import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Box,
  Text,
  Tag,
  Button,
  Heading,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import Card from "components/card/Card.js";
import { SearchInstitution, GetInstitutions, DeleteInstitution } from "services/institutionService";
import { checkprivileges } from "helpers/utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiArrowLeft, FiEye } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEditNote } from "react-icons/md";

export default function Index() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isInstitutionDrawerOpen, onOpen: onInstitutionDrawerOpen, onClose: onInstitutionDrawerClose } = useDisclosure();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [institution, setInstitution] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [institutions, setInstitutions] = useState([]);
  const [institutionId, setInstitutionId] = useState("");

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false
      };
      const response = await GetInstitutions(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setInstitutions(response?.data?.institutions);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteInstitution = async () => {
    try {
      setLoading(true);
      const response = await DeleteInstitution(institutionId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Institution deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getInstitutions();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      const searchData = {
        deleted: false,
        type: "admin",
        searchText
      };
      setLoading(true);
      const response = await SearchInstitution(searchData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setInstitutions(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Institutions", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getInstitutions();
  }, []);

  return (
    <>
      <Drawer isOpen={isInstitutionDrawerOpen} placement="right" onClose={onInstitutionDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Institution Details</DrawerHeader>
          <DrawerBody>
            <Box>
              <Flex gap="2">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Institution Name</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.institutionName}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Institution Address</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.institutionAddress}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Phone</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.phone}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Email</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.email}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">City</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.city}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Test API Key</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.testApiKey}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Production API Key</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.productionApiKey}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Query Commission</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.queryCommission}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Download Commission</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{institution && institution.downloadCommission}</Text>
              </Flex>
            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button color="dark" mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="brand"
              width="90px"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteInstitution();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}> Institutions
        </Text>
      </Box>

      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        w="100%"
        p="2"
        spacing="2"
        mb="4"
      >
        <Box mb="5">
          <Button
            variant="brand"
            width="130px"
            onClick={() => {
              navigate("/admin/institutions/addinstitution");
            }}
          >
            <Icon as={FaPlus} width="16px" height="16px" />&nbsp; Add Institution
          </Button>
        </Box>
        <Box width="100%" textAlign="right">
          <TextFilter
            searchText={searchText}
            setSearchText={setSearchText}
            searchTextLabel="Search By Institution Name"
            handleSearch={handleSearch}
          />
        </Box>
      </SimpleGrid>

      {loading ? (
        <LoadingInstitutions />
      ) : (
        <TableContainer>
          <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
            <Thead>
              <Tr>
                <Th color="#000000">Name</Th>
                <Th color="#000000">Email</Th>
                <Th color="#000000">Phone</Th>
                {privileges.includes("Admin") &&
                  <Th color="#000000">Action</Th>
                }
              </Tr>
            </Thead>
            <Tbody>
              {institutions !== null &&
                institutions.map((institution) => (
                  <Tr>
                    <Td>{institution.institutionName} </Td>
                    <Td>{institution.email}</Td>
                    <Td>{institution.phone}</Td>
                    {privileges.includes("Admin") &&
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="brand"
                            width="100px"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate("/admin/institutions/addinstitution/" + institution._id, {
                                  state: { institutionId: institution._id },
                                });
                              }}
                            >
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp;Edit &nbsp;
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setInstitutionId(institution._id);
                                //setInstitution(institution);
                                onOpen();
                              }}
                            >
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp; Delete &nbsp;
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setInstitution(institution);
                                onInstitutionDrawerOpen();
                              }}>
                              <Icon
                                as={FiEye}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp;
                              View &nbsp;
                            </MenuItem>

                          </MenuList>
                        </Menu>
                      </Td>
                    }
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {pageCount > 0 && (
        <Box width="100%" mt="5">
          <Paginate
            pageCount={pageCount}
            setFrom={setFrom}
            getRecords={getInstitutions}
          />
        </Box>
      )}
    </>
  );
};

const LoadingInstitutions = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};