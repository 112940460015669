import React from "react";
import {
    Input,
  } from "@chakra-ui/react";

  const CustomPhoneInput = React.forwardRef(({ value, onChange, ...props }, ref) => {
    return (
      <Input
        {...props} // Ensures that all necessary props are passed
        ref={ref}  // Attach the ref so that it's recognized as a proper input
        value={value || ''} 
        onChange={(e) => onChange(e.target.value)}
        type="tel" 
      />
    );
  });
  
  export default CustomPhoneInput;