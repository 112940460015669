import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Skeleton,
  SkeletonText,
  useToast
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Card from "components/card/Card.js";
import { GetCollections } from "services/transactionService";
import { checkprivileges } from "helpers/utils";

export default function Index() {
  const navigate = useNavigate();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const getCollections = async () => {
    try {
      setLoading(true);
      const response = await GetCollections(token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setTransactions(response?.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["View Reports", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getCollections();
  }, []);

  return (
    <>

      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}> Collections
        </Text>
      </Box>

      {loading ? (
        <LoadingTransactions />
      ) : (
        <TableContainer mt="5">
          {userState.isSuperAdmin ? <AdminTransactions transactions={transactions} /> : <PartnerTransactions transactions={transactions} />}
        </TableContainer>
      )}

    </>
  );
};


const AdminTransactions = ({ transactions }) => {
  return (
    <>
      <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
        <Thead>
          <Tr>
            <Th color="#000000">Institution</Th>
            <Th color="#000000">All Time Revenue (N)</Th>
            <Th color="#000000">Partner All Time Revenue (N)</Th>
            <Th color="#000000">Platform All Time Revenue (N)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            transactions.map((transaction) => (
              <Tr>
                <Td>{transaction.name} </Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.allTimeRevenue)} </Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.partnerAllTimeRevenue)}</Td>
                <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.platformAllTimeRevenue)}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

const PartnerTransactions = ({ transactions }) => {
  return (
    <>
      <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
        <Thead>
          <Tr>
            <Th color="#000000">Name</Th>
            <Th color="#000000">All Time Revenue (N)</Th>
          </Tr>
        </Thead>
        <Tbody>
          {transactions.map((transaction) => (
            <Tr>
              <Td>{transaction.name} </Td>
              <Td>{new Intl.NumberFormat('en-US', { style: 'decimal' }).format(transaction.allTimeRevenue)}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </>
  );
};

const LoadingTransactions = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};