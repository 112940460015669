import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiUsers, FiHome } from "react-icons/fi";

import Login from "views/auth/login";
import Register from "views/auth/register";

const routes = [
  {
    name: "Sign Up",
    nameAbbrv: "Sign Up",
    layout: "/auth",
    path: "signup",
    icon: <Icon as={FiHome} width="20px" height="20px" color="#000000" />,
    component: <Register />,
    collapse: false,
    allow: ["Admin", "Member"],
  },
  {
    name: "Login",
    nameAbbrv: "Login",
    layout: "/auth",
    path: "login",
    icon: <Icon as={FiHome} width="20px" height="20px" color="#000000" />,
    component: <Login />,
    collapse: false,
    allow: ["Admin", "Member"],
  },
  
];

export default routes;
