import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  VStack,
  Badge,
  SimpleGrid,
  Heading,
  Avatar,
  Center,
  Icon,
  Text,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useDisclosure
} from "@chakra-ui/react";

import { GetAdminGroupByID, UpdateAdminGroupByID } from "services/adminGroupService";
import { FiUser, FiPhone, FiGlobe, FiHome } from "react-icons/fi";
import { FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import { checkprivileges } from "helpers/utils";

export default function Roles() {
  const navigate = useNavigate();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges")
    ? userState.privileges
    : [];

  const [loading, setLoading] = useState(false);
  const [adminGroup, setAdminGroup] = useState(null);
  const [adminGroupData, setAdminGroupData] = useState({
    adminGroupName: "",
    adminGroupAddress: "",
    email: "",
    phone: "",
    city: "",
    state: ""
  });

  const getAdminGroup = async () => {
    try {
      setLoading(true);
      const response = await GetAdminGroupByID(userState.adminGroup, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setAdminGroup(response.data);
        setAdminGroupData(
          {
            adminGroupName: response.data.adminGroupName,
            adminGroupAddress: response.data.adminGroupAddress,
            email: response.data.email,
            phone: response.data.phone,
            city: response.data.city,
            state: response.data.state
          }
        );
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await UpdateAdminGroupByID(adminGroupData, userState.adminGroup, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      getAdminGroup();
      onDrawerClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["OrganizationAdmin", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getAdminGroup();
  }, []);

  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Update Organization Info</DrawerHeader>
          <DrawerBody>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Organization Name</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  placeholder="Organization Name"
                  readOnly={true}
                  disabled={true}
                  defaultValue={adminGroupData && adminGroupData.adminGroupName}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      adminGroupName: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>


            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Address</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  type="text"
                  readOnly={true}
                  disabled={true}
                  placeholder="Enter Address"
                  defaultValue={adminGroupData && adminGroupData.adminGroupAddress}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      adminGroupAddress: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">City</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  type="text"
                  readOnly={true}
                  disabled={true}
                  placeholder="Enter City"
                  defaultValue={adminGroupData && adminGroupData.city}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      city: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">State</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  type="text"
                  readOnly={true}
                  disabled={true}
                  placeholder="Enter State"
                  defaultValue={adminGroupData && adminGroupData.state}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      state: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Email</FormLabel>
                <Input
                  variant="main"
                  maxLength={150}
                  placeholder="Enter Email"
                  defaultValue={adminGroupData && adminGroupData.email}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      email: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Phone</FormLabel>
                <Input
                  variant="main"
                  maxLength={14}
                  placeholder="Enter Phone"
                  defaultValue={adminGroupData && adminGroupData.phone}
                  onChange={(e) => {
                    setAdminGroupData(prevOrganization => ({
                      ...prevOrganization,
                      phone: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box textAlign="left" mt="5" width="100%">
              <Button
                variant="brand"
                width="150px"
                isLoading={loading}
                loadingText="Wait..."
                onClick={handleUpdate}
              >Update
              </Button>
            </Box>

          </DrawerBody>
        </DrawerContent>
      </Drawer>

        <Center width="100%">
          <Box mt={{ sm: "40px", md: "100px" }} width={{ sm: "90%", md: "50%" }} mb="20">
            {adminGroup &&
              <>
                <Box width="100%" textAlign="center">
                  <Avatar
                    size='lg'
                    bg='teal.500'
                    name={adminGroup && adminGroup.adminGroupName}
                    icon={<Icon as={FiUser} width="18px" height="18px" mt="1" />}
                  />
                  <Heading mt="4" size={{ sm: "md", md: "lg" }} textAlign="center" color="#45005E"> {adminGroup && adminGroup.adminGroupName} </Heading>
                </Box>

                <SimpleGrid columns="2" width="100%" mt="5" background="#f2f9f9" p="5" borderRadius="10">
                  <Box><Flex gap="3"><Icon as={FiPhone} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{adminGroup.phone}</Text> </Flex></Box>
                  <Box> <Flex gap="3"><Icon as={FaEnvelope} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{adminGroup.email}</Text> </Flex></Box>
                </SimpleGrid>

                <VStack width="100%" spacing="6" mt="7" background="#f2f9f9" borderRadius="10" p="5" pb="8">
                  <Box width="100%">
                    <Flex direction={"row"} flexDirection={"start"} gap="3">
                      <Icon as={FiHome} width="18px" height="18px" mt="1" />
                      <Text fontFamily="ManropeRegular"
                        fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                        {adminGroup.adminGroupName}
                      </Text>
                    </Flex>
                  </Box>

                  <Box width="100%">
                    <Flex direction={"row"} flexDirection={"start"} gap="3">
                      <Icon as={FaMapMarkerAlt} width="18px" height="18px" mt="1" />
                      <Text fontFamily="ManropeRegular"
                        fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                        {adminGroup.adminGroupAddress} {adminGroup.city && ", " + adminGroup.city}  {adminGroup.state && ", " + adminGroup.state}  {adminGroup.country && ", " + adminGroup.country}
                      </Text>
                    </Flex>
                  </Box>

                  <Box width="100%">
                    <Flex direction={"row"} flexDirection={"start"} gap="3">
                      <Icon as={FiGlobe} width="18px" height="18px" mt="1" />
                      <Text fontFamily="ManropeRegular"
                        fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                        {adminGroup.siteUrl}
                      </Text>
                    </Flex>
                  </Box>

                  <Box width="100%" mt="4">
                    <Flex direction={"row"} flexDirection={"start"} gap="3">
                      <Badge fontFamily="ManropeRegular" variant="brand"
                        fontSize={{ sm: "md", md: "lg" }}>
                        Test API Key
                      </Badge>
                      <Text fontFamily="ManropeRegular"
                        fontSize={{ sm: "md", md: "lg" }}>
                        {adminGroup.testApiKey}
                      </Text>

                    </Flex>
                  </Box>

                  <Box width="100%">
                    <Flex direction={"row"} flexDirection={"start"} gap="3">

                      <Badge fontFamily="ManropeRegular" variant="brand"
                        fontSize={{ sm: "md", md: "lg" }}>
                        Live API Key
                      </Badge>
                      <Text fontFamily="ManropeRegular"
                        fontSize={{ sm: "md", md: "lg" }}>
                        {adminGroup.productionApiKey}
                      </Text>

                    </Flex>
                  </Box>

                </VStack>

                <Box width="100%" textAlign={"center"} mt="7">
                  <Button
                    width="200px"
                    variant="brand"
                    size="md"
                    onClick={onDrawerOpen}
                  >
                    Edit Organization
                  </Button>
                </Box>
              </>
            }
          </Box>
        </Center>

    </>
  );
}
