import { background } from "@chakra-ui/icons";
import { mode } from "@chakra-ui/theme-tools";
export const buttonStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: "16px",
        fontFamily: "ManropeLight",
        boxShadow: "45px 76px 113px 7px rgba(112, 144, 176, 0.08)",
        transition: ".25s all ease",
        boxSizing: "border-box",
        _focus: {
          boxShadow: "none",
        },
        _active: {
          boxShadow: "none",
        },
      },
      variants: {
        outline: (props) => ({
          fontSize: "sm",
          fontWeight: "bold",
          w: "100%",
          h: "50",
          borderRadius: "15px",
          color: mode("brand.500", "white")(props),
        }),
        ghost: (props) => ({
          h: "50",
          fontSize: "sm",
          fontWeight: "bold",
          color: "white",
          background:  mode("brand.500", "brand.500")(props),
          _focus: {
            bg: mode("brand.500", "brand.500")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.500")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.500")(props),
          },
        }),
        brand: (props) => ({
          fontSize: "sm",
          fontWeight: "bold",
          w: "100%",
          h: "50",
          borderRadius: "15px",
          bg: mode("brand.500", "brand.500")(props),
          color: "white",
          _focus: {
            bg: mode("brand.500", "brand.500")(props),
          },
          _active: {
            bg: mode("brand.500", "brand.500")(props),
          },
          _hover: {
            bg: mode("brand.600", "brand.500")(props),
          },
        }),
        primary: (props) => ({
          bg: mode("blue.400", "blue.500")(props),
          color: "white",
          _focus: {
            bg: mode("blue.400", "blue.500")(props),
          },
          _active: {
            bg: mode("blue.400", "blue.500")(props),
          },
          _hover: {
            bg: mode("blue.400", "blue.500")(props),
          },
        }),
        darkBrand: (props) => ({
          bg: mode("brand.900", "brand.400")(props),
          color: "white",
          _focus: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _active: {
            bg: mode("brand.900", "brand.400")(props),
          },
          _hover: {
            bg: mode("brand.800", "brand.400")(props),
          },
        }),
        lightBrand: (props) => ({
          bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("#F2EFFF", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        light: (props) => ({
          bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _active: {
            bg: mode("secondaryGray.300", "whiteAlpha.100")(props),
          },
          _hover: {
            bg: mode("secondaryGray.400", "whiteAlpha.200")(props),
          },
        }),
        action: (props) => ({
          fontWeight: "500",
          borderRadius: "50px",
          bg: mode("secondaryGray.300", "brand.400")(props),
          color: mode("brand.500", "white")(props),
          _focus: {
            bg: mode("secondaryGray.300", "brand.400")(props),
          },
          _active: { bg: mode("secondaryGray.300", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.200", "brand.400")(props),
          },
        }),
        setup: (props) => ({
          fontWeight: "500",
          fontFamily: "ManropeLight",
          borderRadius: "50px",
          bg: mode("transparent", "brand.400")(props),
          border: mode("1px solid", "0px solid")(props),
          borderColor: mode("secondaryGray.400", "transparent")(props),
          color: mode("secondaryGray.900", "white")(props),
          _focus: {
            bg: mode("transparent", "brand.400")(props),
          },
          _active: { bg: mode("transparent", "brand.400")(props) },
          _hover: {
            bg: mode("secondaryGray.100", "brand.400")(props),
          },
        }),
      },
    },
  },
};
