import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Heading,
  Flex,
  Text,
  Stack,
  Image,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

function VerifyEmail() {
  return (
    <>
      <Helmet>
        <title>Verify</title>
        <meta name="description" content=" Create an account" />
        <meta name="keywords" content="" />
      </Helmet>

      <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">

        <Flex width={"100%"} justify={"center"}>
          <Stack
            spacing={4}
            mx={"auto"}
            mt={{sm: "30px", md: "0px" }}
            w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
          >
            <Stack p={2} align={"left"}>
              <Image src={Logo} width="70px" height="auto" />
              <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
                Verify Email Address
              </Heading>
              <Text mt={"10"} textAlign={"left"} fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                Please check your email for the verification link
              </Text>
            </Stack>

          </Stack>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default VerifyEmail;
