import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormLabel,
  FormControl,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  Checkbox,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { UpdateInstitutionByID, CreateInstitution, GetInstitutionByID, GetInstitution } from "services/institutionService";
import { GetAdminGroup } from "services/adminGroupService";
import { GetCountries } from "services/miscService";
import { GetRoles } from "services/roleService";
import { checkprivileges } from "helpers/utils";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

export default function AddInstitution() {
  const navigate = useNavigate();
  const { institutionId } = useParams();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [adminGroups, setAdminGroups] = useState([]);
  const [countries, setCountries] = useState([]);
  const [roles, setRoles] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [institution, setInstitution] = useState({
    institutionName: "",
    institutionAddress: "",
    email: "",
    phone: "",
    city: "",
    country: "",
    state: "",
    siteUrl: "",
    adminGroup: "",
    role: "",
    queryCommission: "",
    downloadCommission: "",
    password: "",
    commissionBased: false
  });

  const handleAddInstitution = async () => {
    try {
      setLoading(true);
      const response = !institutionId ? await CreateInstitution(institution, token) : await UpdateInstitutionByID(institution, institutionId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/admin/institutions/institutions");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getAdminGroups = async () => {
    try {
      setLoading(true);
      const response = await GetAdminGroup(token);
      setLoading(false);
      if (response.success) {
        setAdminGroups(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries();
      setLoading(false);
      if (response.success) {
        setCountries(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getInstitution = async () => {
    try {
      setLoading(true);
      const response = institutionId ? await GetInstitutionByID(institutionId, token) : await GetInstitution(userState.institution, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setInstitution({
          institutionName: response.data.institutionName,
          institutionAddress: response.data.institutionAddress,
          email: response.data.email,
          phone: response.data.phone,
          city: response.data.city,
          country: response.data.country,
          state: response.data.state,
          siteUrl: response.data.siteUrl,
          adminGroup: response.data.adminGroup,
          queryCommission: response.data.queryCommission,
          downloadCommission: response.data.downloadCommission,
          commissionBased: response.data.commissionBased
        })
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Institutions", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    if (institutionId) {
      getInstitution();
    }
    getAdminGroups();
    getCountries();
    getRoles();
  }, []);

  return (
    <>
      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            >  {!institutionId ? "Add Institution" : "Update Institution"}  </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              variant="brand"
              width="100px"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/admin/institutions/institutions");
              }}
            >
              Institutions
            </Button>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Institution Name</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                placeholder="Institution Name"
                defaultValue={institution.institutionName}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    institutionName: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>

          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Address</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter Address"
                defaultValue={institution.institutionAddress}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    institutionAddress: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">City</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter City"
                defaultValue={institution.city}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    city: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">State</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter State"
                defaultValue={institution.state}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    state: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl id="siteUrl" isRequired>
              <FormLabel fontFamily="ManropeSemiBold">Site Url</FormLabel>
              <Input
                type={"text"}
                variant="main"
                maxLength={200}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    siteUrl: e.target.value
                  }));
                }}
                placeholder="Enter Site Url"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Country</FormLabel>
              <Select
                variant="main"
                placeholder="Select Option"
                value={institution.country}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    country: e.target.value
                  }));
                }}
              >
                {
                  countries.map((country) => (
                    <option key={country._id} value={country._id}>
                      {country.countryName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </SimpleGrid>



        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl id="queryCommission" isRequired>
              <FormLabel fontFamily="ManropeSemiBold">Query Commission</FormLabel>
              <Input
                type={"text"}
                variant="main"
                maxLength={200}
                value={institution.queryCommission}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    queryCommission: e.target.value
                  }));
                }}
                placeholder="Enter Query Commission"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl id="downloadCommission" isRequired>
              <FormLabel fontFamily="ManropeSemiBold">Download Commission</FormLabel>
              <Input
                type={"text"}
                variant="main"
                maxLength={200}
                value={institution.downloadCommission}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    downloadCommission: e.target.value
                  }));
                }}
                placeholder="Enter Download Commission"
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Reffered By Organization</FormLabel>
              <Select
                variant="main"
                placeholder="Select Option"
                value={institution.referredBy}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    adminGroup: e.target.value
                  }));
                }}
              >
                {
                  adminGroups.map((adminGroup) => (
                    <option key={adminGroup._id} value={adminGroup._id}>
                      {adminGroup.adminGroupName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">{institutionId ? "Email" : "Super Admin Email"}</FormLabel>
              <Input
                variant="main"
                maxLength={150}
                type="email"
                placeholder={institutionId ? "Enter Email" : "Enter Super Admin Email"}
                defaultValue={institution.email}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    email: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">{institutionId ? "Enter Phone" : "Super Admin Phone"}</FormLabel>
              <Input
                variant="main"
                maxLength={15}
                type="tel"
                placeholder={institutionId ? "Enter Phone" : "Enter Super Admin Phone"}
                defaultValue={institution.phone}
                onChange={(e) => {
                  setInstitution(prevInstitution => ({
                    ...prevInstitution,
                    phone: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        {!institutionId && <>
          <SimpleGrid
            width="100%"
            columns={{ base: 1, md: 2 }}
            spacing="6"
            mb="15">
            <Box>
              <FormControl id="password" isRequired>
                <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    variant="main"
                    maxLength={100}
                    onChange={(e) => {
                      setInstitution(prevInstitution => ({
                        ...prevInstitution,
                        password: e.target.value
                      }));
                    }}
                    placeholder="Enter Password"
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            </Box>
            <Box>
              <FormControl isRequired>
                <FormLabel fontWeight="bold">Role</FormLabel>
                <Select
                  variant="main"
                  placeholder="Select Option"
                  value={institution.role}
                  onChange={(e) => {
                    setInstitution(prevInstitution => ({
                      ...prevInstitution,
                      role: e.target.value
                    }));
                  }}
                >
                  {
                    roles !== null && roles.length > 0 &&
                    roles.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.roleName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>
          </SimpleGrid>
        </>
        }

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <Checkbox
              size='lg'
              colorScheme='green'
              isChecked={institution.commissionBased}
              onChange={(e) => {
                setInstitution(prevInstitution => ({
                  ...prevInstitution,
                  commissionBased: e.target.checked  // Use 'checked' for checkboxes
                }));
              }}
            >
              Commission Based
            </Checkbox>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>

        <Box textAlign="left" mt="3" width="100%">
          <Button
            variant="brand"
            width="150px"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddInstitution}
          >
            {!institutionId ? "Create" : "Update"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
