import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  FormLabel,
  FormControl,
  Input,
  Text,
  Checkbox,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { CreateRole, GetRole, UpdateRole } from "services/roleService";
import { checkprivileges } from "helpers/utils";

export default function AddRole() {
  const location = useLocation();
  const navigate = useNavigate();

  const roleId = location.state?.roleId;
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const userPrivileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];

  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const [loading, setLoading] = useState(false);
  const [privileges, setPrivileges] = useState([]);
  const [privilegesOrganization, setPrivilegesOrganization] = useState([]);

  const [role, setRole] = useState({
    roleName: "",
    roleAbbreviation: "",
    privileges: ""
  });

  const handleAddRole = async () => {
    try {
      setLoading(true);
      role.privileges = privileges.concat(privilegesOrganization);
      const response = roleId
        ? await UpdateRole(role, roleId, token)
        : await CreateRole(role, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/admin/settings/roles");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRole = async () => {
    try {
      setLoading(true);
      const response = await GetRole(roleId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRole({
          roleName: response.data.roleName,
          roleAbbreviation: response.data.roleAbbreviation,
          privileges: response.data.privileges
        });
        const userPrivileges = response.data.privileges !== null && response.data.privileges !== "" ? response.data.privileges : [];
        setPrivileges(userPrivileges);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const addPrivilege = async (privilege, type = "super") => {
    if (type === "super") {
      if (!privileges.includes(privilege)) {
        const updatedPrivileges = [...privileges, privilege];
        setPrivileges(updatedPrivileges);
      }
    }
    else {
      if (!privilegesOrganization.includes(privilege)) {
        const updatedPrivileges = [...privilegesOrganization, privilege];
        setPrivilegesOrganization(updatedPrivileges);
      }
    }
  };

  const removePrivilege = async (privilege, type = "super") => {
    if (type === "super") {
      const indexToRemove = privileges.indexOf(privilege);
      const updatedPrivileges = [...privileges];
      if (indexToRemove !== -1) {
        updatedPrivileges.splice(indexToRemove, 1);
      }
      setPrivileges(updatedPrivileges);
    } else {
      const indexToRemove = privilegesOrganization.indexOf(privilege);
      const updatedPrivileges = [...privilegesOrganization];
      if (indexToRemove !== -1) {
        updatedPrivileges.splice(indexToRemove, 1);
      }
      setPrivilegesOrganization(updatedPrivileges);
    }
  };

  const handleCheck = (privilege, isChecked) => {
    if (isChecked) {
      addPrivilege(privilege);
    } else {
      removePrivilege(privilege);
    }
  };

  const handleCheckOrganization = (privilege, isChecked) => {
    if (isChecked) {
      addPrivilege(privilege, "organization");
    } else {
      removePrivilege(privilege, "organization");
    }
  };

  const handleSelectAll = (isChecked) => {
    if (isChecked) {
      const allPrivileges = ['Admin', 'Manage Certificates', 'Manage Users', 'View Reports', 'Manage Roles', 'Manage Organizations', 'Manage Institutions']
      setPrivileges(allPrivileges);
    } else {
      setPrivileges([]);
    }
  };

  const handleSelectAllOrganization = (isChecked) => {
    if (isChecked) {
      const allPrivilegesOrganization = ['OrganizationAdmin', 'Manage Users', 'View Report', 'Manage Settings']
      setPrivilegesOrganization(allPrivilegesOrganization);
    } else {
      setPrivilegesOrganization([]);
    }
  };

  useEffect(() => {
    !checkprivileges(userPrivileges, ["Manage Roles", "OrganizationAdmin", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    if (roleId) {
      getRole();
    }
  }, []);

  return (
    <>
      <Box pt={{ base: "120px", md: "120px", xl: "120px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Roles
            </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/app/settings/roles");
              }}
            >
              Roles
            </Button>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="5"
          mb="3"
        >
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Name</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                placeholder="Role Name"
                defaultValue={role.roleName}
                onChange={(e) => {
                  setRole({ ...role, roleName: e.target.value });
                }}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold">Role Abbreviation</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                placeholder="Role Abbreviation"
                defaultValue={role.roleAbbreviation}
                onChange={(e) => {
                  setRole({ ...role, roleAbbreviation: e.target.value });
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        {
          checkprivileges(userPrivileges, ["Admin"]) &&
          <Box mt="10">
            <Flex mt="2">
              <Checkbox
                name="admin"
                isChecked={privileges.includes("Admin")}
                onChange={(e) => {
                  handleSelectAll(e.target.checked); // Only call handleSelectAll here
                }}
              />
              &nbsp;
              <Text>All Privileges</Text>
            </Flex>

            <Text fontWeight="bold">Others</Text>
            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("Manage Users")}
                onChange={(e) => handleCheck("Manage Users", e.target.checked)}
              />
              &nbsp;
              <Text>Manage Users</Text>
            </Flex>

            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("Manage Roles")}
                onChange={(e) => handleCheck("Manage Roles", e.target.checked)}
              />
              &nbsp;
              <Text>Manage Roles</Text>
            </Flex>

            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("Manage Institutions")}
                onChange={(e) => handleCheck("Manage Institutions", e.target.checked)}
              />
              &nbsp;
              <Text>Manage Institutions</Text>
            </Flex>

            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("Manage Organizations")}
                onChange={(e) => handleCheck("Manage Organizations", e.target.checked)}
              />
              &nbsp;
              <Text>Manage Organizations</Text>
            </Flex>

            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("Manage Certificates")}
                onChange={(e) => handleCheck("Manage Certificates", e.target.checked)}
              />
              &nbsp;
              <Text>Manage Certificates</Text>
            </Flex>

            <Flex mt="2">
              <Checkbox
                isChecked={privileges.includes("View Reports")}
                onChange={(e) => handleCheck("View Reports", e.target.checked)}
              />
              &nbsp;
              <Text>View Reports</Text>
            </Flex>
          </Box>
        }


        <Box mt="10">
          <Text fontWeight="bold">Organization Admin</Text>
          <Flex mt="2">
            <Checkbox
              isChecked={privilegesOrganization.indexOf("OrganizationAdmin") !== -1}
              onChange={(e) => {
                handleCheckOrganization("OrganizationAdmin", e.target.checked);
                handleSelectAllOrganization(e.target.checked);
              }}
            />
            &nbsp;
            <Text>All Privileges</Text>
          </Flex>

          <Text fontWeight="bold">Others</Text>
          <Flex mt="2">
            <Checkbox
              isChecked={privilegesOrganization.indexOf("Manage Users") !== -1}
              onChange={(e) =>
                handleCheckOrganization("Manage Users", e.target.checked)
              }
            />
            &nbsp;
            <Text>Manage Users</Text>
          </Flex>
          <Flex mt="2">
            <Checkbox
              isChecked={privilegesOrganization.indexOf("View Report") !== -1}
              onChange={(e) =>
                handleCheckOrganization("View Report", e.target.checked)
              }
            />
            &nbsp;
            <Text>View Report</Text>
          </Flex>
          <Flex mt="2">
            <Checkbox
              isChecked={privilegesOrganization.indexOf("Manage Settings") !== -1}
              onChange={(e) =>
                handleCheckOrganization("Manage Settings", e.target.checked)
              }
            />
            &nbsp;
            <Text>Manage Settings</Text>
          </Flex>
        </Box>

        <Box mt="5" width="100%" textAlign="left">
          <Button
            variant={"brand"}
            width="150px"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddRole}
          >
            {roleId ? "Update" : "Create"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
