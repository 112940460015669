import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";
import {
  Flex,
  Box,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Center,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import axios from "axios";
import DefaultAuth from "layouts/auth/Default";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


function Otp() {
  //const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const toast = useToast();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");
  const [firstCode, setFirstCode] = useState(true);
  const [remainingTime, setRemainingTime] = useState(60); // 60 seconds = 1 minute
  const [showResendLink, setShowResendLink] = useState(false);
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const handleLoginWithOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(
        SERVER_URL + "/user/loginWithOtp",
        { email: location.state.email, otp: otp },
        {
          headers: {
            "Authorization": ''
          },
          mode: 'cors'
        }
      );
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);

        if (response.data.twoFaActivated) {
          navigate("/auth/twofactor/" + response.data._id);
          return;
        }

        dispatch(setUser(response.data));
        if (response.data.firstName === null || !response.data.profileCompleted) {
          navigate("/app/settings/setup");
        } else {
          navigate("/app/dashboard");
        }

        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getOtp = async () => {
    try {
      setLoading(true);
      const { data: response } = await axios.post(SERVER_URL + "/user/getOtp", { email: location.state.email }, {
        headers: {
          "Authorization": ''
        },
        mode: 'cors'
      });
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setFirstCode(true);
        setRemainingTime(60);
        setShowResendLink(false);
      }
      setLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
      return;
    }
  };

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  useEffect(() => {
    let timerId = null;
    if (remainingTime > 0) {
      timerId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1);
      }, 1000);
    } else {
      setShowResendLink(true);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [remainingTime]);

  return (
    <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">
      <Flex width={"100%"} justify={"center"}>
        <Stack
          spacing={4}
          mx={"auto"}
          w={{ base: "95%", sm: "95%", md: "60%", lg: "50%" }}
        >

          <Stack p={2} align={"left"}>
            <Image src={Logo} width="70px" height="auto" />
            <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
              OTP Code
            </Heading>
            <Text
              fontFamily="ManropeRegular"
              fontSize={"lg"}
              textAlign="center"
            >
              Enter the OTP Code sent to your email
            </Text>
          </Stack>

          <Box rounded={"lg"} p={2} width="100%">
            <Stack spacing={4} width="100%">
              <Box pt="3" pb="5">
                <Center width="100%">
                  <PinInput otp>
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin1(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin2(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin3(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin4(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin5(e.target.value);
                      }}
                    />
                    <PinInputField
                      w="55px"
                      h="55px"
                      color={"black"}
                      onChange={(e) => {
                        setPin6(e.target.value);
                      }}
                    />
                  </PinInput>
                  {showResendLink ? (
                    firstCode ? (
                      <Button size="xs" onClick={getOtp}>
                        Send
                      </Button>
                    ) : (
                      <Button size="xs" onClick={getOtp}>
                        Send
                      </Button>
                    )
                  ) : (
                    <Text>&nbsp;{remainingTime}s </Text>
                  )}
                </Center>
              </Box>


              <Stack spacing={10} pt={2}>
                <Button
                  variant="brand"
                  mb="10px"
                  type="submit"
                  isLoading={loading}
                  loadingText="Wait..."
                  onClick={(e) => {
                    e.preventDefault();
                    handleLoginWithOtp();
                  }}
                >
                  Proceed
                </Button>
              </Stack>
              <Stack pt={6}>
                <Text align={"center"} color={"dark.600"} fontSize="md">
                  Back to &nbsp;
                  <Text color="brand.500" as="span">
                    <Link to={"/auth/login"}>
                      Login
                    </Link>
                  </Text>
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </DefaultAuth>
  );
}

export default Otp;
