import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormLabel,
  FormControl,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { CreateUser, UpdateUser, UpdateUserByID, GetUser, GetUserByID } from "services/userService";
import { GetRoles } from "services/roleService";

export default function AddUser() {

  const navigate = useNavigate();
  const { userId } = useParams();

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    role: ""
  });

  const handleAddUser = async () => {
    try {
      setLoading(true);
      user["userType"] = "organization";
      const response = !userId ? await CreateUser(user, token) : await UpdateUserByID(user, userId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/app/users/organizationusers");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getUser = async () => {
    try {
      setLoading(true);
      const response = userId ? await GetUserByID(userId, token) : await GetUser(token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUser({
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          email: response.data.email,
          phone: response.data.phone
        })
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    /*if(!privileges.includes("Manage Users") && !privileges.includes("Admin")){
      toast({
        title: "You are not allowed",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      navigate("/auth/dashboard");
      return;
    }*/
    if (userId) {
      getUser();
    }
    getRoles();
  }, []);

  return (
    <>
      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            >  {!userId ? "Add User" : "Update User"}  </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/app/users/organizationusers");
              }}
            >
              Users
            </Button>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">First Name</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                placeholder="First Name"
                defaultValue={user.firstName}
                onChange={(e) => {
                  setUser(prevUser => ({
                    ...prevUser,
                    firstName: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Last Name</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                type="text"
                placeholder="Last Name"
                defaultValue={user.lastName}
                onChange={(e) => {
                  setUser(prevUser => ({
                    ...prevUser,
                    lastName: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Email</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                type="email"
                placeholder="Email"
                defaultValue={user.email}
                onChange={(e) => {
                  setUser(prevUser => ({
                    ...prevUser,
                    email: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Phone</FormLabel>
              <Input
                variant="main"
                maxLength={50}
                type="tel"
                placeholder="Phone"
                defaultValue={user.phone}
                onChange={(e) => {
                  setUser(prevUser => ({
                    ...prevUser,
                    phone: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl>
              <FormLabel fontWeight="bold">Role</FormLabel>
              <Select
                variant="main"
                placeholder="Select Option"
                value={user.role}
                onChange={(e) => {
                  setUser(prevUser => ({
                    ...prevUser,
                    role: e.target.value
                  }));
                }}
              >
                {
                  roles !== null && roles.length > 0 &&
                  roles.map((role) => (
                    <option key={role._id} value={role._id}>
                      {role.roleName}
                    </option>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            {
              !userId &&
              <FormControl id="password" isRequired>
                <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                <InputGroup>
                  <Input
                    type={showPassword ? "text" : "password"}
                    variant="main"
                    maxLength={100}
                    onChange={(e) => {
                      setUser(prevUser => ({
                        ...prevUser,
                        password: e.target.value
                      }));
                    }}
                    placeholder="Enter Password"
                  />
                  <InputRightElement h={"full"}>
                    <Button
                      variant={"ghost"}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                    >
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
            }
          </Box>
        </SimpleGrid>


        <Box textAlign="left" mt="3" width="100%">
          <Button
            variant="brand"
            width="150px"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddUser}
          >
            {!userId ? "Create" : "Update"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
