import React, { useEffect, useState } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { GetDashboardSummary, GetPast30DaysSummary } from "services/transactionService"
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import Revenue from "./components/Revenue";
import { checkprivileges } from "helpers/utils";

import {
  MdAttachMoney,
  MdBarChart
} from "react-icons/md";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [revenue, setRevenue] = useState(null);
  const [stat, setStat] = useState(null);

  const filter = {
    institutions: []
  }

  const getDashboardSummary = async () => {
    try {
      setLoading(true);
      const response = await GetDashboardSummary(filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setStat(response?.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getPast30DaysSummary = async () => {
    try {
      setLoading(true);
      const response = await GetPast30DaysSummary(filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setCategories(response.data.days)
        setRevenue([
          {
            name: "Revenue",
            data: response.data.totalPlatformRevenue,
          },
          {
            name: "Verifications",
            data: response.data.count,
          }
        ])
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    getDashboardSummary();
    getPast30DaysSummary();
  }, [])


  return (
    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total Revenue'
          value={stat && stat.totalAmount}
        />

       {
        checkprivileges(privileges, ["Admin"]) &&
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total Platform Revenue'
          value={stat && stat.totalPlatformRevenue}
        />
       }

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today Query'
          value={stat && stat.count}
        />
        {/*<MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today Completed Sell Trade'
          value={dailyStatistics.todayCompletedSellTrade}
        />*/}
      </SimpleGrid>
      {revenue && <Revenue categories={categories} revenue={revenue} />}
    </Box>
  );
}
