import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText
} from "@chakra-ui/react";
import Card from "components/card/Card";

export default function TextFilter(props) {
  const { searchText, setSearchText, searchTextLabel, handleSearch } = props;
  return (
    <Box
      w='100%'
      mb='20px'
    >
      <FormControl>
        {/*<FormLabel htmlFor='date' fontWeight="bold">Filter By {searchTextLabel} </FormLabel>*/}
        <Input
          variant="search" maxLength={"150"} type='text' value={searchText} defaultValue={searchText} placeholder={searchTextLabel} onChange={(e) => {
            setSearchText(e.target.value)
          }}  onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSearch();
            }
          }} />
      </FormControl>
    </Box>
  );
}