import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormLabel,
  FormControl,
  Select,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  Checkbox,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { checkprivileges } from "helpers/utils";
import { UpdateAdminGroupByID, CreateAdminGroup, GetAdminGroupByID } from "services/adminGroupService";
import { GetRoles } from "services/roleService";

export default function AddOrganization() {
  const navigate = useNavigate();
  const { organizationId } = useParams();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const [organization, setOrganization] = useState({
    adminGroupName: "",
    adminGroupAddress: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    password: "",
    role: "",
    queryCommission: "",
    downloadCommission: "",
    commissionBased: false
  });

  const handleAddOrganization = async () => {
    try {
      setLoading(true);
      if (organizationId) {
        delete organization["password"];
        delete organization["role"];
      }
      const response = !organizationId ? await CreateAdminGroup(organization, token) : await UpdateAdminGroupByID(organization, organizationId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      navigate("/admin/organizations/organizations");
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await GetRoles(token);
      setLoading(false);
      if (response.success) {
        setRoles(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getAdminGroup = async () => {
    try {
      setLoading(true);
      const response = organizationId ? await GetAdminGroupByID(organizationId, token) : await GetAdminGroupByID(userState.adminGroup, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setOrganization({
          adminGroupName: response.data.adminGroupName,
          adminGroupAddress: response.data.adminGroupAddress,
          email: response.data.email,
          phone: response.data.phone,
          city: response.data.city,
          state: response.data.state,
          queryCommission: response.data.queryCommission,
          downloadCommission: response.data.downloadCommission,
          commissionBased: response.data.commissionBased
        });
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Organizations", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    if (organizationId) {
      getAdminGroup();
    }
    getRoles();
  }, []);

  return (
    <>
      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="20">
        <SimpleGrid
          width="100%"
          columns={{ base: 2, md: 2 }}
          spacing="2"
          mt="4"
          mb="15"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            >  {!organizationId ? "Add Partner" : "Update Partner"}  </Text>
          </Box>
          <Box width="100%" textAlign="right">
            <Button
              colorScheme="teal"
              background="black"
              color="#ffffff"
              _hover={{ color: "brand.500", background: "black" }}
              size="md"
              onClick={() => {
                navigate("/admin/organizations/organizations");
              }}
            >
              Partners
            </Button>
          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Partner Name</FormLabel>
              <Input
                variant="main"
                maxLength={250}
                placeholder="Partner Name"
                defaultValue={organization.adminGroupName}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    adminGroupName: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>

          </Box>
        </SimpleGrid>


        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Address</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter Address"
                defaultValue={organization.adminGroupAddress}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    adminGroupAddress: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">City</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter City"
                defaultValue={organization.city}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    city: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">State</FormLabel>
              <Input
                variant="main"
                maxLength={200}
                type="text"
                placeholder="Enter State"
                defaultValue={organization.state}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    state: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold"> {!organizationId ? "Super Admin Email" : "Email"}</FormLabel>
              <Input
                variant="main"
                maxLength={150}
                type="email"
                placeholder="Email"
                defaultValue={organization.email}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    email: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl isRequired>
              <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">{!organizationId ? "Super Admin Phone" : "Phone"}</FormLabel>
              <Input
                variant="main"
                maxLength={15}
                type="tel"
                placeholder="Phone"
                defaultValue={organization.phone}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    phone: e.target.value,
                  }));
                }}
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        {
          !organizationId &&
          <>
            <SimpleGrid
              width="100%"
              columns={{ base: 1, md: 2 }}
              spacing="6"
              mb="15">
              <Box>
                <FormControl id="password" isRequired>
                  <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      variant="main"
                      maxLength={100}
                      onChange={(e) => {
                        setOrganization(prevOrganization => ({
                          ...prevOrganization,
                          password: e.target.value
                        }));
                      }}
                      placeholder="Enter Password"
                    />
                    <InputRightElement h={"full"}>
                      <Button
                        variant={"ghost"}
                        onClick={() =>
                          setShowPassword((showPassword) => !showPassword)
                        }
                      >
                        {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
              </Box>
              <Box>
                <FormControl>
                  <FormLabel fontWeight="bold">Role</FormLabel>
                  <Select
                    variant="main"
                    placeholder="Select Option"
                    value={organization.role}
                    onChange={(e) => {
                      setOrganization(prevOrganization => ({
                        ...prevOrganization,
                        role: e.target.value
                      }));
                    }}
                  >
                    {
                      roles !== null && roles.length > 0 &&
                      roles.map((role) => (
                        <option key={role._id} value={role._id}>
                          {role.roleName}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>
            </SimpleGrid>
          </>
        }

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <FormControl id="queryCommission" isRequired>
              <FormLabel fontFamily="ManropeSemiBold">Query Commission</FormLabel>
              <Input
                type={"text"}
                variant="main"
                maxLength={200}
                value={organization.queryCommission}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    queryCommission: e.target.value
                  }));
                }}
                placeholder="Enter Query Commission"
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl id="downloadCommission" isRequired>
              <FormLabel fontFamily="ManropeSemiBold">Download Commission</FormLabel>
              <Input
                type={"text"}
                variant="main"
                maxLength={200}
                value={organization.downloadCommission}
                onChange={(e) => {
                  setOrganization(prevOrganization => ({
                    ...prevOrganization,
                    downloadCommission: e.target.value
                  }));
                }}
                placeholder="Enter Download Commission"
              />
            </FormControl>
          </Box>
        </SimpleGrid>

        <SimpleGrid
          width="100%"
          columns={{ base: 1, md: 2 }}
          spacing="6"
          mb="15">
          <Box>
            <Checkbox
              size='lg'
              colorScheme='green'
              isChecked={organization.commissionBased}
              onChange={(e) => {
                setOrganization(prevOrganization => ({
                  ...prevOrganization,
                  commissionBased: e.target.checked  // Use 'checked' for checkboxes
                }));
              }}
            >
              Commission Based
            </Checkbox>
          </Box>
          <Box>
          </Box>
        </SimpleGrid>


        <Box textAlign="left" mt="3" width="100%">
          <Button
            variant="brand"
            width="150px"
            isLoading={loading}
            loadingText="Wait..."
            onClick={handleAddOrganization}
          >
            {!organizationId ? "Create" : "Update"}
          </Button>
        </Box>
      </Box>
    </>
  );
}
