import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Flex,
  Box,
  Image,
  PinInput,
  PinInputField,
  Spinner,
  Center,
  Stack,
  Button,
  Heading,
  Text,
  useToast,
  useColorModeValue,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { setUser } from "utils/userSlice";
import { UserLoginWithOTP } from "services/userService";
import DefaultAuth from "layouts/auth/Default";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

function Index() {
  //const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const userId = id;
  const toast = useToast();
  const [pin1, setPin1] = useState("");
  const [pin2, setPin2] = useState("");
  const [pin3, setPin3] = useState("");
  const [pin4, setPin4] = useState("");
  const [pin5, setPin5] = useState("");
  const [pin6, setPin6] = useState("");
  const [otp, setOtp] = useState("");
  const inputColor = useColorModeValue("brand.500", "gray.100");

  const handleLogin = async () => {
    try {
      setLoading(true);
      const user = { userId, otp };
      const response = await UserLoginWithOTP(user);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);
        dispatch(setUser(response.data));
        if (response.data.accountType === "personal" || response.data.accountType === "organization") {
          navigate("/app/dashboard");
        }
        else if (response.data.accountType === "admin") {
          navigate("/admin/dashboard");
        }
        return;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setOtp(pin1 + "" + pin2 + "" + pin3 + "" + pin4 + "" + pin5 + "" + pin6);
  }, [pin1, pin2, pin3, pin4, pin5, pin6, otp]);

  return (
    <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">
      <Flex width={"100%"} justify={"center"}>
        <Stack
          spacing={4}
          mx={"auto"}
          mt={{sm: "30px", md: "0px" }}
          w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
        >

          <Stack p={2} align={"left"}>
            <Image src={Logo} width="70px" height="auto" />
            <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
              Two Factor Authentication
            </Heading>
            <Text
              fontFamily="ManropeRegular"
              fontSize={"lg"}
              textAlign="left"
            >
              Enter the OTP Code sent to your email
            </Text>
          </Stack>

          <Stack spacing={4} width="100%" mt="4">
            <Center width="100%">
              <PinInput otp width="100%">
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin1(e.target.value);
                  }}
                />
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin2(e.target.value);
                  }}
                />
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin3(e.target.value);
                  }}
                />
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin4(e.target.value);
                  }}
                />
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin5(e.target.value);
                  }}
                />
                <PinInputField
                  w="16.6%"
                  h="65px"
                  color={"black"}
                  onChange={(e) => {
                    setPin6(e.target.value);
                  }}
                />
              </PinInput>
              {loading && <Spinner ml="1" />}
            </Center>

            <Stack spacing={10} pt={2}>
              <Button
                variant="brand"
                mb="10px"
                type="submit"
                isLoading={loading}
                loadingText="Wait..."
                onClick={(e) => {
                  e.preventDefault();
                  handleLogin();
                }}
              >
                Proceed
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={"center"} color={"dark.600"} fontSize="md">
                Back to &nbsp;
                <Text color="brand.500" as="span">
                  <Link to={"/auth/login"}>
                    Login
                  </Link>
                </Text>
              </Text>
            </Stack>
          </Stack>

        </Stack>
      </Flex>
    </DefaultAuth>
  );
}

export default Index;
