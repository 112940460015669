import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Image,
  Stack,
  Button,
  Heading,
  CircularProgress,
  Text,
  useToast,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";
// Assets
import { UserVerify } from "services/userService";

function Verify() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activated, setActivated] = useState({});
  const { code } = useParams();
  const toast = useToast();

  const verifyEmail = async () => {
    try {
      setLoading(true);
      const response = await UserVerify({ code });
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setLoading(false);
        setActivated(response);
        toast({
          title: "Account has been verified, you can now login",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/auth/login");
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (code) {
      verifyEmail();
    }
  }, [code]);

  return (
    <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">

      <Flex width={"100%"} justify={"center"}>
        <Stack
          spacing={4}
          mx={"auto"}
          mt={{sm: "30px", md: "0px" }}
          w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
        >

          {!loading &&
            activated.hasOwnProperty("success") &&
            activated.success && (
              <>
                <Stack p={2} align={"left"}>
                  <Image src={Logo} width="70px" height="auto" />
                  <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
                    Hurray!!!
                  </Heading>
                  <Text mt={"10"} textAlign={"left"} fontFamily={"ManropeRegular"} fontSize={{ base: "sm", sm: "md", md: "lg", lg: "xl" }}>
                    Your email has been verified successfully,  You can now proceed to your account
                  </Text>

                </Stack>

                <Stack
                  direction={"column"}
                  spacing={3}
                  align={"center"}
                  alignSelf={"center"}
                  position={"relative"}
                >
                  <Link to="/auth/login">
                    <Button
                      variant="brand"
                      bg={"green.400"}
                      rounded={"full"}
                      px={6}
                      _hover={{
                        bg: "green.500",
                      }}
                    >
                      Back to Login
                    </Button>{" "}
                  </Link>
                </Stack>
              </>)
          }


          {loading && !activated.hasOwnProperty("success") && (
            <Box width="100%" textAlign={"center"}>
              <CircularProgress isIndeterminate color="green.300" />
            </Box>
          )}

          {!loading &&
            activated.hasOwnProperty("success") &&
            !activated.success && (
              <Stack
                spacing={8}
                mx={"auto"}
                w={{ base: "95%", sm: "95%", md: "70%", lg: "65%" }}
              >
                <Stack align={"center"}>
                  <Stack
                    as={Box}
                    textAlign={"center"}
                    spacing={{ base: 8, md: 14 }}
                    py={{ base: 2, md: 6 }}
                  >
                    <Heading
                      fontWeight={600}
                      fontSize={{ base: "xl", sm: "2xl", md: "4xl" }}
                      lineHeight={"70%"}
                    >
                      Ooops!!! <br />
                      <br />
                      <Text
                        as={"span"}
                        fontSize={{ base: "sm", sm: "xl", md: "2xl" }}
                        color={"green.400"}
                      >
                        {activated.message}
                      </Text>
                    </Heading>

                  </Stack>
                </Stack>
              </Stack>
            )}
        </Stack>
      </Flex>
    </DefaultAuth>
  );
}

export default Verify;
