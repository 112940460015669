import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Select,
  Button,
  Heading,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tag,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Skeleton,
  SkeletonText,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import Card from "components/card/Card.js";
import {
  AddCertificate,
  SearchCertificate,
  GetCertificates,
  DeleteCertificate,
  DownloadCertificate,
  UpdateCertificateByID
} from "services/certificateService";
import { GetInstitution } from "services/institutionService";
import { checkprivileges } from "helpers/utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiArrowLeft, FiEye } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { MdDelete, MdEditNote } from "react-icons/md";

export default function Index() {
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isCertificateDrawerOpen, onOpen: onCertificateDrawerOpen, onClose: onCertificateDrawerClose } = useDisclosure();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [certificate, setCertificate] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const [institutions, setIntitutions] = useState([]);
  const [certificateId, setCertificateId] = useState("");
  const [certificateData, setCertificateData] = useState({
    name: "",
    faculty: "",
    department: "",
    course: "",
    degree: "",
    page: "",
    year: "",
    affliatedInstitution: "",
    institution: "",
    key: ""
  });

  const handleGetCertificate = async (certificate) => {
    setCertificateData({
      name: certificate.name,
      faculty: certificate.faculty,
      department: certificate.department,
      course: certificate.course,
      degree: certificate.degree,
      page: certificate.page,
      year: certificate.year,
      affliatedInstitution: certificate.affliatedInstitution,
      institution: certificate.institution._id,
      key: certificate.key
    })
  }

  const getCertificates = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };
      const response = await GetCertificates(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setCertificates(response?.data?.certificates);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = certificateId ? await UpdateCertificateByID(certificateData, certificateId, token) : await AddCertificate(certificateData, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        onDrawerClose();
      }
      getCertificates();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const initFilterSubmit = async () => {
    try {
      setLoading(true);
      const searchData = {
        searchText,
        deleted: false
      };
      const response = await SearchCertificate(searchData, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setLoading(false);
        setCertificates(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteCertificate = async () => {
    try {
      setLoading(true);
      const response = await DeleteCertificate(certificateId, token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "Certificate deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getCertificates();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const downloadCertificate = async () => {
    try {
      setDownloadLoading(true);
      const response = await DownloadCertificate(certificateId, token);
      setDownloadLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.success) {
        setIntitutions(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Certificates", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getCertificates();
    getInstitutions();
  }, []);

  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">{certificateId ? "Update Academic Record" : "Add Academic Record"}</DrawerHeader>
          <DrawerBody>
            <Box>
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Bearer Name</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  placeholder="Bearer Name"
                  defaultValue={certificateData && certificateData.name}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      name: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Degree</FormLabel>
                <Input
                  variant="main"
                  maxLength={300}
                  placeholder="Degree"
                  defaultValue={certificateData && certificateData.degree}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      degree: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Faculty</FormLabel>
                <Input
                  variant="main"
                  maxLength={300}
                  placeholder="Faculty"
                  defaultValue={certificateData && certificateData.faculty}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      faculty: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Course</FormLabel>
                <Input
                  variant="main"
                  maxLength={300}
                  placeholder="Course"
                  defaultValue={certificateData && certificateData.course}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      course: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Department</FormLabel>
                <Input
                  variant="main"
                  maxLength={300}
                  placeholder="Course"
                  defaultValue={certificateData && certificateData.department}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      department: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold">Year</FormLabel>
                <Select
                  variant="main"
                  placeholder="Select Option"
                  value={certificateData.year}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      year: e.target.value
                    }));
                  }}
                >
                  {
                    [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024].map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold">Institution</FormLabel>
                <Select
                  variant="main"
                  placeholder="Select Instituition"
                  value={certificateData && certificateData.institution}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      institution: e.target.value
                    }));
                  }}
                >
                  {
                    institutions.length > 0 &&
                    institutions.map((institution) => (
                      <option key={institution._id} value={institution._id}>
                        {institution.institutionName}
                      </option>
                    ))}
                </Select>
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Key</FormLabel>
                <Input
                  variant="main"
                  maxLength={100}
                  placeholder="Enter Key"
                  defaultValue={certificateData && certificateData.key}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      key: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Page</FormLabel>
                <Input
                  variant="main"
                  maxLength={100}
                  placeholder="Enter Page"
                  defaultValue={certificateData && certificateData.page}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      page: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="2">
              <FormControl>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Affliated Institution</FormLabel>
                <Input
                  variant="main"
                  maxLength={300}
                  placeholder="Enter Affliated Institution"
                  defaultValue={certificateData && certificateData.affliatedInstitution}
                  onChange={(e) => {
                    setCertificateData(prevCertificate => ({
                      ...prevCertificate,
                      affliatedInstitution: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box textAlign="left" mt="2" width="100%">
              <Button
                variant="brand"
                width="150px"
                isLoading={loading}
                loadingText="Wait..."
                onClick={handleSubmit}
              >{certificateId ? "Update" : "Add"}
              </Button>
            </Box>

          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={isCertificateDrawerOpen} placement="right" onClose={onCertificateDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Academic Record Details</DrawerHeader>
          <DrawerBody>

            <Box>
              <Flex gap="2">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Bearer Name</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.name}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Degree</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.degree}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Faculty</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.faculty}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Course</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.course}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Department</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.department}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Year</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.year}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Institution</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.institution && certificate.institution.institutionName}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Key</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.key}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Page</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.page}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Tag fontSize={{ sm: "md", md: "lg" }} background="brand.500" color="white" fontWeight="bold">Affliated Institution</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{certificate && certificate.affliatedInstitution}</Text>
              </Flex>

              <Flex gap="2" mt="3">
                <Button
                  variant="primary"
                  isLoading={downloadLoading}
                  loadingText="..Wait"
                  onClick={(e) => {
                    downloadCertificate();
                  }}>Dowload Full Report</Button>
              </Flex>

            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteCertificate();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Box mt={{ sm: "40px", md: "100px" }} mb="20">
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          w="100%"
          p="2"
          spacing="2"
          mb="4"
        >
          <Box mb="5">
            {" "}
            <Text
              color={"black"}
              mb="4px"
              fontFamily={"ManropeSemiBold"}
              fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
            > Certificates
            </Text>
          </Box>
          <Box textAlign="right">
          </Box>
        </SimpleGrid>

        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          w="100%"
          p="2"
          spacing="5"
          mt="3"
        >
          <Box>
            <Button
              variant="brand"
              width="140px"
              onClick={() => {
                setCertificateId("");
                onDrawerOpen();
              }}>
              <Icon as={FaPlus} width="16px" height="16px" />&nbsp;Add Certificate
            </Button>
          </Box>
          <TextFilter
            searchText={searchText}
            setSearchText={setSearchText}
            searchTextLabel="Name/Certificate ID"
            initFilterSubmit={initFilterSubmit}
          />
        </SimpleGrid>

        {loading ? (
          <LoadingCertificates />
        ) : (
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th color="#000000">Bearer Name</Th>
                  <Th color="#000000">Institution</Th>
                  <Th color="#000000">Faculty</Th>
                  <Th color="#000000">Department</Th>
                  <Th color="#000000">Year</Th>
                  <Th color="#000000">Page</Th>
                  <Th color="#000000">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {certificates &&
                  certificates.map((certificate) => (
                    <Tr>
                      <Td>{certificate.name}</Td>
                      <Td>{certificate?.institution?.institutionName}</Td>
                      <Td>{certificate.faculty}</Td>
                      <Td>{certificate.department}</Td>
                      <Td>{certificate?.year}</Td>
                      <Td>{certificate?.page}</Td>
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="brand"
                            width="100px"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>

                            {privileges.includes("Admin") && <>
                              <MenuItem
                                onClick={() => {
                                  handleGetCertificate(certificate);
                                  setCertificateId(certificate._id);
                                  onDrawerOpen();
                                }}
                              >
                                Edit &nbsp;{" "}
                                <Icon
                                  as={MdEditNote}
                                  width="20px"
                                  height="20px"
                                  color="black"
                                />{" "}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  setCertificateId(certificate._id);
                                  onOpen();
                                }}
                              >
                                Delete &nbsp;{" "}
                                <Icon
                                  as={MdDelete}
                                  width="20px"
                                  height="20px"
                                  color="black"
                                />{" "}
                              </MenuItem> </>
                            }

                            <MenuItem
                              onClick={() => {
                                setCertificate(certificate);
                                setCertificateId(certificate._id);
                                onCertificateDrawerOpen();
                              }}>
                              View &nbsp;{" "}
                              <Icon
                                as={FiEye}
                                width="20px"
                                height="20px"
                                color="black"
                              />{" "}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}
        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getCertificates}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

const LoadingCertificates = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};