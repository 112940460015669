import axiosInstance from "./axiosInstance"; // Adjust the path accordingly
const SERVER_URL = process.env.REACT_APP_SERVER_URL;


const GetInstitution = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/institution", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateInstitution = async (InstitutionData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/institution", InstitutionData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetInstitutionByID = async (institutionId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/institution/"+institutionId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateInstitution = async (institutionData, institutionId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/institution/"+institutionId,
      institutionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetInstitutions = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/institution/getInstitutions?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchInstitution = async (searchData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/institution/search", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateInstitutionByID = async (institutionData, institutionId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/institution/" + institutionId,
      institutionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteInstitution = async (institutionId, token) => {
  try {
    const response = await axiosInstance.delete(SERVER_URL + "/institution/" + institutionId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


export { GetInstitution, UpdateInstitutionByID, UpdateInstitution, CreateInstitution, SearchInstitution, GetInstitutions, GetInstitutionByID, DeleteInstitution  };
