import axiosInstance from './axiosInstance'; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const Checkout = async (paymentData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/payment/checkout", paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CheckoutUnit = async (paymentData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/payment/checkoutunit", paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const VerifyPayment = async (paymentData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/payment/verify", paymentData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const FetchPayments = async (filter, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/payment/fetchPayments", filter, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetPayments = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
        "/payment?from=" +
        pagination.from +
        "&limit=" +
        pagination.limit +
        "&orderBy=" +
        pagination.orderBy +
        "&sortOrder=" +
        pagination.sortOrder, filter, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  Checkout,
  CheckoutUnit,
  VerifyPayment,
  GetPayments,
  FetchPayments
};




